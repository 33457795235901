<template>
  <div v-if="!isMobile()" class="error--text text-h6 text-center">
    {{ $t("messages.function_work_on_mobile_only") }}
  </div>
  <div v-else>
    <v-row dense class="mb-2">
      <v-col
        cols="3"
        v-for="item in tabs"
        :key="item.status"
        class="text-center"
      >
        <span
          :class="filters.status == item.status ? 'primary--text' : ''"
          class="fs-14 text-decoration-underline"
          @click="onTabChange(item.status)"
        >
          {{ item.text }}
          <br />
          <span v-if="item.status < 5">
            ({{ counts[`count_status_${item.status}`] }})
          </span>
        </span>
      </v-col>
    </v-row>

    <div style="height: calc(100vh - 180px); overflow-y: auto">
      <v-simple-table
        class="table-padding-2 mb-5"
        v-for="(item, key) in items"
        :key="`r_${key}_${item.sku}`"
      >
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th
                role="columnheader"
                colspan="4"
                class="text-center purple white--text"
              >
                <span class="fs-16">
                  SKU: {{ item.sku }} - {{ $t("labels.remaining_quantity") }} :
                  {{ totalByItem(item) }}
                </span>
                <br />
                <span class="fs-14">
                  <span v-if="item.locations && item.locations.length > 0">
                    {{ item.locations.join("; ") }}
                    <br />
                  </span>
                  <span
                    v-if="
                      item.expand_locations && item.expand_locations.length > 0
                    "
                  >
                    Mở rộng: {{ item.expand_locations.join("; ") }}
                  </span>
                </span>
              </th>
            </tr>
            <tr>
              <th role="columnheader" class="text-center">
                {{ $t("labels.basket") }}
              </th>
              <th
                role="columnheader"
                class="text-center"
                v-if="filters.status != 4"
              >
                {{ $t("labels.remaining_quantity") }}
              </th>
              <th role="columnheader" class="text-center">Pick</th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.pack") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="text-center"
              v-for="(child, cKey) in item.finds"
              :key="`c_${cKey}_${item.sku}`"
            >
              <td class="font-weight-medium error--text">
                {{ child.basket_code }}
              </td>
              <td class="font-weight-medium" v-if="filters.status != 4">
                <span class="fs-30">{{ child.remaining_quantity }}</span>
              </td>
              <td>{{ child.employee_pickup_name }}</td>
              <td>{{ child.employee_packing_name }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>

    <div class="section-sticky-bottom">
      <v-row dense>
        <v-col cols="6">
          <v-btn small block color="warning" @click="showDialogNotFound">{{
            $t("labels.find_goods_status_3")
          }}</v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn small block color="primary" @click="showDialogFinded">{{
            $t("labels.find_goods_history_type_1")
          }}</v-btn>
        </v-col>
      </v-row>
    </div>

    <v-dialog v-model="dialogNotFound" persistent max-width="400px">
      <FindGoodsNotFound
        v-if="dialogNotFound"
        @cancel="closeDialogNotFound"
        @refreshData="getList"
      />
    </v-dialog>

    <v-dialog v-model="dialogFinded" persistent max-width="400px">
      <FindGoodsFinded
        v-if="dialogFinded"
        @cancel="closeDialogFinded"
        @refreshData="getList"
      />
    </v-dialog>
  </div>
</template>
<script>
import { httpClient } from "@/libs/http";
import {
  debounce,
  formatDateTime,
  formatNumber,
  downloadExcelFile,
  getUrlParameter,
  isMobile,
} from "@/libs/helpers";
import { FINDING_STATUS } from "@/libs/const";
export default {
  name: "Find",
  components: {
    FindGoodsFinded: () => import("@/components/goods/FindGoodsFinded"),
    FindGoodsNotFound: () => import("@/components/goods/FindGoodsNotFound"),
  },
  data: () => ({
    tabs: [...FINDING_STATUS],
    tab: 0,
    page: 1,
    counts: {},
    items: [],
    filters: {
      status: 1,
    },
    isLoading: false,
    dialogNotFound: false,
    dialogFinded: false,
    itemDetail: {},
  }),
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    if (!this.isMobile()) {
      return false;
    }
    this.getList();
  },
  methods: {
    debounce,
    isMobile,
    formatDateTime,
    formatNumber,
    downloadExcelFile,
    getUrlParameter,
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    onTabChange(tab) {
      this.filters = { ...this.filters, status: tab };
    },
    getList: debounce(function () {
      httpClient
        .post("/find-goods/v1/employee-list", {
          ...this.filters,
          page: this.page,
        })
        .then(({ data }) => {
          this.counts = { ...data.counts };
          this.items = [...data.rows];
        });
    }, 500),
    showDialogNotFound() {
      this.dialogNotFound = true;
    },
    closeDialogNotFound() {
      this.dialogNotFound = false;
    },
    showDialogFinded() {
      this.dialogFinded = true;
    },
    closeDialogFinded() {
      this.dialogFinded = false;
    },
    totalByItem(item) {
      let total = 0;
      item.finds.forEach((child) => {
        total += child.remaining_quantity;
      });
      return total;
    },
  },
};
</script>
